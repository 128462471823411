<template>
    <div class="appoinService">
        <div @click="$router.go(-1)" class="leftArrow">
            <svg-icon iconClass="leftArrow"></svg-icon>
        </div>
        <van-tabs swipeable animated v-model="activeIndex" @change="changeTab">
            <van-tab title="解锁">
                <div class="tab_content">
                    <unlock />
                </div>
            </van-tab>
            <van-tab title="预约">
                <div class="tab_content">
                    <reserve />
                </div>
            </van-tab>

        </van-tabs>
    </div>
</template>

<script>
    import unlock from './unlock';
    import reserve from './reserve';
    export default {
        components: {
            reserve,
            unlock,
        },
        data() {
            return {
                activeIndex: 0, // 选中Tab下标
            }
        },
        created() {
            if (this.$route.query.i) {
                this.activeIndex = Number(this.$route.query.i);
            } else {
                this.activeIndex = 0;
            }
        },
        methods: {
            changeTab(index) {
                this.$router.replace({path: '/appoinService', query: {i: index}})
            }
        }
    }
</script>

<style lang='scss' scoped>
    .appoinService {
        position: relative;

        .leftArrow {
            position: absolute;
            left: 16px;
            top: 22px;
            transform: translate(0, -50%);
            font-size: 15px;
            color: $white1;
            z-index: 3;
        }

        /deep/ .van-tabs__wrap {
            background: $vermillion;
        }

        /deep/ .van-tabs__nav {
            width: 180px;
            margin: 0 auto;
            background: $vermillion;
        }

        /deep/ .van-tab__text {
            font-size: 15px;
            color: $white1;
        }

        /deep/ .van-tabs__line {
            background: $white1;
            bottom: 20px;
            width: 26px;
            // width: 50%;
        }

        /deep/ .van-tab--active {
            .van-tab__text {
                font-weight: 600;
                font-size: 18px;
            }

        }

        .tab_content {
            // padding: 0 16px;
            // overflow: auto;
            // -ms-overflow-style: none;
            height: calc(100vh - 44px);
            // background: $whiteThree;
        }
    }
</style>