<template>
  <div class="reserve">
    <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
      :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
      <div class="reservePage">
        <div class="cardBox" v-for="(item, index) in lfList" :key="index" @click="jumpLfDetail(item)">
          <ImgDecypt :src="item.img" class="imageUrl" />
          <div class="infoBox">
            <div class="title">{{item.title}}</div>
            <div>
              <!-- <div class="infoItem"><span>订单号：</span>*******8</div> -->
              <div class="infoItem"><span>地区：</span>{{handleArea(item.province, item.city)}}</div>
              <div class="infoItem"><span>价格：</span>{{item.srvPrice}}</div>
              <div class="infoItem"><span>预付定金：</span>{{`${item.bookPrice}(实际支付${item.realPrice})`}}</div>
              <div class="infoItem"><span>评分：</span>
                <van-rate :value="item.star" size="10" color="#f2a32f" void-icon="star" />
              </div>
              <div class="infoItem"><span>服务：</span>{{item.service}}</div>
              <div class="infoItem"><span>详情：</span>{{item.detail}}</div>
            </div>
            <div class="btnBox">
              <div class="btn">举报</div>
              <div class="btn" v-if="item.canReport">发布体验</div>
            </div>
          </div>
        </div>
      </div>
    </Pullrefresh>
  </div>

</template>

<script>
  import { queryLfBought } from "@/api/mine/bought.js";
  import Pullrefresh from "@/views/widget/PullRefresh.vue";
  export default {
    components: {
      Pullrefresh,
    },
    data() {
      return {
        loading: false,
        finished: false,
        refreshing: false,
        isNoData: false,
        firstLoading: true, //是否第一次加载
        pageNum: 1,
        pageSize: 10,
        lfList: [],
      };
    },
    created() {
      this.getData();
    },
    methods: {
      //上滑加载
      onLoad() {
        this.pageNum++;
        this.getData();
      },
      //刷新
      onRefresh() {
        this.refreshing = true;
        this.finished = false;
        this.pageNum = 1;
        this.lfList = [];
        this.getData();
      },
      //获取数据
      async getData() {
        this.loading = true;
        let req = {
          buyType: 1,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        let ret = await this.$Api(queryLfBought, req);

        this.loading = false;
        this.refreshing = false;
        this.firstLoading = false;

        if (ret && ret.code == 200) {
          let list = ret.data.list ? ret.data.list : [];
          this.lfList = this.lfList.concat(list);

          if (list.length == 0 && this.pageNum == 1) {
            this.isNoData = true;
            return;
          }
          if (list.length < this.pageSize) {
            this.finished = true;
          }
        }
      },
      // 处理地区
      handleArea(province, city) {
        let str = "";
        if (province == "全国" || province == city || !city) {
          str = province;
        } else {
          str = `${province}-${city}`;
        }
        return str;
      },
      // 跳转楼凤详情
      jumpLfDetail(item) {
        this.$router.push({
          name: "loufengDetail",
          params: {
            id: item.id,
          },
        });
      },
    },
  };
</script>

<style lang='scss' scoped>
  .reserve {
    height: calc(100vh - 44px);
  }
  .reservePage {
    padding: 20px 10px 0;

    .cardBox {
      height: 190px;
      padding: 6px;
      border-radius: 4px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
      display: flex;
      justify-content: space-between;

      .imageUrl {
        width: 129px;
        height: 100%;
        border-radius: 6px;
        overflow: hidden;
      }

      .infoBox {
        flex: 2;
        margin-left: 23px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          font-size: 14px;
          font-weight: 600;
          width: 190px;
          height: 24px;
          line-height: 24px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .infoItem {
          width: 178px;
          font-size: 10px;
          margin-bottom: 6px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 14px;
          line-height: 14px;

          span {
            font-weight: 600;
          }
        }

        .total {
          font-size: 12px;
          // line-height: 15px;
          height: 15px;
          margin: 11px 0 15px;

          .total_svg {
            width: 15px;
            height: 15px;
            color: $vermillion;
            margin-right: 2px;
          }

          span {
            margin-right: 6px;
          }
        }

        .btnBox {
          display: flex;

          .btn {
            width: 62px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            color: $white1;
            font-size: 10px;
            border-radius: 4px;
            background-image: linear-gradient(180deg,
                $tomato 0,
                $macaroniAndCheese 100%);
            margin-right: 20px;
            box-shadow: 0 1px 2px $dustyOrange;
            font-weight: 600;
            margin-bottom: 4px;
          }
        }
      }
    }
  }
</style>